import React from "react";
import Navbar from "../components/molecules/navbar";
import "./home.css";

export default function Home() {
    return <>
    <Navbar />
    <h1>Mobizo Home</h1>
    </>
}
